import Quiz1Question from './quiz1/Quiz1Question'
import Quiz1Result from './quiz1/Quiz1Result'
import Quiz1Preview from "./quiz1/Quiz1Preview"
import Quiz2Result from "./quiz2/Quiz2Result"

export default {
    Quiz1Question,
    Quiz1Result,
    Quiz1Preview,
    Quiz2Result
}
